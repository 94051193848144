import PropTypes from 'prop-types';
import classNames from "classnames";
import { markdownToHtml } from "../markdown/markdownToHtml";

import styles from './styles/Markdown.module.scss';
import {useContext} from "react";
import {StoreContext} from "../../stores/StoreLoader";



export default function Markdown({withStyles, source, allowLinks = true, ...restProps}) {
    const {interfaceStore} = useContext(StoreContext);
    const markdownClassname = classNames({
        [styles.markdown]: withStyles === true,
    });

    if (!allowLinks) {
        source = source?.replace(/\[.*\]\(.*\)/g, "") || "";
    }

    const contents = markdownToHtml(source, {currentFullUrl: interfaceStore.currentFullUrl, allowGfmLinks: allowLinks});

    return <div className={markdownClassname} {...restProps} dangerouslySetInnerHTML={{__html: contents}} />
}

Markdown.defaultProps = {
    withStyles: false,
    allowLinks: true,
};

Markdown.propTypes = {
    withStyles: PropTypes.bool,
    source: PropTypes.string,
    allowLinks: PropTypes.bool,
};

